import * as React from "react"

const serializers = {
  marks: {
    link: ({mark, children}) => {
      const { href } = mark
      return <a href={href} target="_blank" rel="noreferrer">{children}</a>
    }
  }
}

export default serializers