import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Seo } from "../components/seo"
import Layout from "../components/layout"
import { container, articleTitle, subtitle, text, articleImg} from "./blog-post.module.css"
import serializers from "../utils/serializers"
const BlockContent = require('@sanity/block-content-to-react')

const BlogPost = ({pageContext: { article }}) => {

  return (
    <Layout containerStyle="noLanding">
      <Seo title={article.title} />

      <div className={container}>
        <div className={articleTitle}>
          <p>{new Date(article.publishedAt).toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})}</p>
          <h1>{article.title}</h1>
          <div className={subtitle}>
            <BlockContent 
              blocks={article._rawSubtitle} 
              serializers={serializers}
            />
          </div>
        </div>
        <div className={articleImg}>
          <GatsbyImage
            alt={""}
            image={article.mainImage.asset.gatsbyImageData}
            loading={"lazy"}
            placeholder="none"
            style={{height: "100%", width: "100%", overflow:"hidden"}}
            imgStyle={{ objectFit: "cover"}}
          /> 
          {/* <img src={article.image.originalSrc} alt={article.title} /> */}
        </div>
        <div className={text}>
          <BlockContent 
            blocks={article._rawBody} 
            serializers={serializers}
            projectId="bhj0qb5m"
            dataset="production"
          />
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost